import { css, Grid, Typography } from "@mui/material";
import { FontFamily, FontWeight } from "@unit/styles";
import { CustomHeadLabelRenderOptions, MUISortOptions } from "mui-datatables";
import Image from "next/image";
import React from "react";
import { BiSortAlt2 } from "react-icons/bi";

type Props = {
  columnOptions: CustomHeadLabelRenderOptions;
  sortOrder?: MUISortOptions | null;
  instagram?: boolean;
  noSort?: boolean;
};

const NewCustomHeadLabel: React.FC<Props> = (props) => {
  const { name, label } = props.columnOptions;
  const isSorting = props.sortOrder?.direction && name === props.sortOrder.name;
  return (
    <Grid position="relative" display="flex" alignItems="center">
      {props.instagram && (
        <Image alt="hint" src="/sns-icons/instagram.svg" width="14" height="14" css={style.instagramIcon} />
      )}
      <Typography css={style.label}>{label}</Typography>
      {props.sortOrder !== null && !props.noSort && !isSorting && <BiSortAlt2 size={18} css={style.icon} />}
    </Grid>
  );
};
export default React.memo(NewCustomHeadLabel);

const style = {
  label: css`
    font-family: ${FontFamily.kintoSans};
    font-weight: ${FontWeight.bold};
    font-size: 12px;
    line-height: 12px;
    color: #353a44;
  `,
  icon: css`
    position: relative;
    right: -4px;
    color: #353a44;
  `,
  instagramIcon: css`
    padding: 1.5px;
  `,
};
