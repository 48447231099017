export const storesPathById = (id: string) => `/stores/${id}`;
export const storeManualPath = "https://help.unit-g.com/store-top/";
export const storeManualPathSecond = "https://forstore.zendesk.com/hc/ja";

type EnvType = "local" | "staging" | "production";

const InfluencerAppURL = {
  local: "https://localhost:3003",
  staging: "https://app-stg.unit-g.com",
  production: "https://app.unit-g.com",
};
const ENV = (process.env.APP_ENV || "production") as EnvType;
export const homePreviewPath = `${InfluencerAppURL[ENV]}/home/preview`;
export const storesNewPath = "/stores/new";
export const storesEditPathById = (id: string) => `/stores/${id}/edit`;
