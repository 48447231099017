import { APICompsSchema } from "./api-config";
import {
  GenderEnum,
  StaffRoleEnum,
  OrganizationTypeEnum,
  DiscountTypeEnum,
  ChatReportTypeEnum,
  TicketTypeEnum,
  StaffStatusEnum,
  InfluencerStatusEnum,
  OrganizationStatusEnum,
  BrandStatusEnum,
  StoreStatusEnum,
  TicketStatusEnum,
  InfluencerTicketStatusEnum,
  InfluencerTicketScheduleStatusEnum,
  PRMethodTypeEnum,
  InfluencerTicketOrderStatusEnum,
  InfluencerLabelEnum,
  StoreTypeEnum,
  InfluencerTicketPriorityEnum,
  InfluencerTicketOfferLabelEnum,
  SpotlightStatusEnum,
  NewInfluencerTicketStatusEnum,
} from "./schema-enum";

export const GenderEnumToValue = (gender: APICompsSchema["GenderEnum"] | null) => {
  return GenderEnum.find((t) => t.type === gender)?.value || "";
};

export const StaffRoleEnumToValue = (staffRole: APICompsSchema["StaffRoleEnum"] | null) => {
  return StaffRoleEnum.find((t) => t.type === staffRole)?.value || "";
};

export const OrganizationTypeEnumToValue = (organizationType: APICompsSchema["OrganizationTypeEnum"] | null) => {
  return OrganizationTypeEnum.find((t) => t.type === organizationType)?.value || "";
};

export const DiscountTypeEnumToValue = (discountType: APICompsSchema["DiscountTypeEnum"] | null) => {
  return DiscountTypeEnum.find((t) => t.type === discountType)?.value || "";
};
export const ChatReportTypeEnumToValue = (chatReportType: APICompsSchema["ChatReportTypeEnum"] | null) => {
  return ChatReportTypeEnum.find((t) => t.type === chatReportType)?.value || "";
};

export const TicketTypeEnumToValue = (ticketType: APICompsSchema["TicketTypeEnum"] | null) => {
  return TicketTypeEnum.find((t) => t.type === ticketType)?.value || "";
};

export const StoreTypeEnumToValue = (storeType: APICompsSchema["StoreTypeEnum"] | null) => {
  return StoreTypeEnum.find((t) => t.type === storeType)?.value || "";
};

export const StaffStatusEnumToValue = (staffStatus: APICompsSchema["StaffStatusEnum"] | null) => {
  return StaffStatusEnum.find((t) => t.type === staffStatus)?.value || "";
};

export const InfluencerStatusEnumToValue = (influencerStatus: APICompsSchema["InfluencerStatusEnum"] | null) => {
  return InfluencerStatusEnum.find((t) => t.type === influencerStatus)?.value || "";
};
export const InfluencerLabelEnumToValue = (influencerStatus: APICompsSchema["InfluencerLabelEnum"] | null) => {
  return InfluencerLabelEnum.find((t) => t.type === influencerStatus)?.value || "";
};

export const OrganizationStatusEnumToValue = (organizationStatus: APICompsSchema["OrganizationStatusEnum"] | null) => {
  return OrganizationStatusEnum.find((t) => t.type === organizationStatus)?.value || "";
};

export const BrandStatusEnumToValue = (brandStatus: APICompsSchema["BrandStatusEnum"] | null) => {
  return BrandStatusEnum.find((t) => t.type === brandStatus)?.value || "";
};

export const StoreStatusEnumToValue = (storeStatus: APICompsSchema["StoreStatusEnum"] | null) => {
  return StoreStatusEnum.find((t) => t.type === storeStatus)?.value || "";
};

export const SpotlightStatusEnumToValue = (spotlightStatus: APICompsSchema["SpotlightStatusEnum"] | null) => {
  return SpotlightStatusEnum.find((t) => t.type === spotlightStatus)?.value || "";
};

export const TicketStatusEnumToValue = (ticketStatus: APICompsSchema["TicketStatusEnum"] | null) => {
  return TicketStatusEnum.find((t) => t.type === ticketStatus)?.value || "";
};

export const InfluencerTicketStatusEnumToValue = (
  influencerTicketStatus: APICompsSchema["InfluencerTicketStatusEnum"] | null,
) => {
  return InfluencerTicketStatusEnum.find((t) => t.type === influencerTicketStatus)?.value || "";
};

export const NewInfluencerTicketStatusEnumToValue = (
  influencerTicketStatus: APICompsSchema["InfluencerTicketStatusEnum"] | null,
) => {
  return NewInfluencerTicketStatusEnum.find((t) => t.type === influencerTicketStatus)?.value || "";
};

export const InfluencerTicketOfferLabelEnumToValue = (
  influencerTicketOfferLabelEnum: APICompsSchema["InfluencerTicketOfferLabelEnum"] | null,
) => {
  return InfluencerTicketOfferLabelEnum.find((t) => t.type === influencerTicketOfferLabelEnum)?.value || "";
};

export const InfluencerTicketPriorityEnumToValue = (
  influencerTicketPriority: APICompsSchema["InfluencerTicketPriorityEnum"] | null,
) => {
  return InfluencerTicketPriorityEnum.find((t) => t.type === influencerTicketPriority)?.value || "未設定";
};

export const InfluencerTicketStatusEnumByTicketTypeToValue = (
  ticketType: APICompsSchema["TicketTypeEnum"],
  influencerTicketStatus: APICompsSchema["InfluencerTicketStatusEnum"] | null,
) => {
  if (ticketType === "visit") {
    return InfluencerTicketStatusEnum.find((t) => t.type === influencerTicketStatus)?.value || "";
  } else if (ticketType === "order") {
    return InfluencerTicketOrderStatusEnum.find((t) => t.type === influencerTicketStatus)?.value || "";
  } else {
    return "";
  }
};

export const InfluencerTicketScheduleStatusEnumToValue = (
  influencerTicketScheduleStatus: APICompsSchema["InfluencerTicketScheduleStatusEnum"] | null,
) => {
  return InfluencerTicketScheduleStatusEnum.find((t) => t.type === influencerTicketScheduleStatus)?.value || "";
};

export const PRMethodTypeEnumToValue = (influencerTicketPRtypeStatus: APICompsSchema["PRMethodTypeEnum"] | null) => {
  return PRMethodTypeEnum.find((t) => t.type === influencerTicketPRtypeStatus)?.value || "";
};
